import React, { FC } from 'react';

import { WWW_HOST } from '~/constants/hosts';

const NotFound: FC = () => {
    window.location.href = `${WWW_HOST}/not_found.htm`;
    return <div>not found</div>;
};

export default NotFound;
